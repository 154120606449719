<template>
            <!--begin::Landing hero-->
            <div class="d-flex flex-column flex-center w-100 min-h-350px min-h-lg-500px px-9">
                <!--begin::Heading-->
                <div class="text-center mb-5 mb-lg-10 py-10 py-lg-20">
                    <!--begin::Title-->
                    <h1 class="text-white lh-base fw-bolder fs-2x fs-lg-3x mb-15">
                        Portail de Déclarations des <br>
                        <span style="background: linear-gradient(to right, #12ce5d 0%, #ffd80c 100%); -webkit-background-clip: text; -webkit-text-fill-color: transparent;">
                            <span id="kt_landing_hero_text">Etats de Reseaux des Permissionnaires (DEREP) </span>
                        </span>
                         en République Gabonaise
                    </h1>
                    <!--end::Title-->
                    <!--begin::Action-->
                    <router-link class="btn btn-primary"  to="#premiere-declaration" data-kt-scroll-toggle="true" data-kt-drawer-dismiss="true">
                        
                        <!--begin::Svg Icon | path: assets/media/icons/duotune/arrows/arr065.svg-->
                        <span class="svg-icon svg-icon-muted svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect opacity="0.5" x="11" y="18" width="13" height="2" rx="1" transform="rotate(-90 11 18)" fill="black"/>
                        <path d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z" fill="black"/>
                        </svg>
                        </span>
                        Faites  votre première déclaration 
                        <!--end::Svg Icon-->
                    </router-link>
                    <!--end::Action-->
                </div>
                <!--end::Heading-->
                <!--begin::Clients-->
                <!--end::Clients-->
            </div>
            <!--end::Landing hero-->
</template>

<script>
export default {

}
</script>

<style>

</style>