import { defineComponent, ref, computed, watch,getCurrentInstance,onMounted } from "vue";
import { useStore } from "vuex";
import _ from "lodash";
import Yup from "@/bundle/validations/YupExpended";
import { ErrorMessage, Field, useForm, useField} from "vee-validate";
import { v4 as uuidv4 } from 'uuid';
import {setData} from './dataFormatter'

interface RadarData {
  id: string;
   bande : {
    id: string;
    libelle?: string;
    debut: number|undefined;
    fin: number|undefined;
   }
   bandeAutreDebut : number|undefined|null,
   bandeAutreFin : number|undefined|null,
   largeurBande:number|undefined
   bandeAutre? : string|undefined|null;
   frequencePorteuse:number|undefined
   sites : Array<Site>
}

interface Bande {
  createdDate: Date|string|undefined;
  lastModifiedDate: Date|string|undefined;
  id: string| undefined;
  libelle?: string;
  debut: number;
  fin: number;
}
interface Site {
  code: string;
  nom: string;
  longitude: string;
  latitude: string;
  entGeo : {
    id:string;
  };
}

interface sendradarData {
  stationA : undefined,
  stationB : undefined
}

interface EntGeo {
  id: string;

}
import useEmitter from '@/composables/useEmitter'
import { useBande } from "@/composables/useBande";
import { useEntGeos } from "@/composables/useEntGeos";

export default defineComponent({
  name: "new-radar-modal",
  components: {ErrorMessage, Field},
  setup(props,context) {

    const emitter = useEmitter();
    const {getBandeParReseau,getBandeDescription,getDefaultBande,bandeIsAutre} = useBande("radar");
    const {villesGabonaises,getEntgeoDescription} = useEntGeos();


    const showModal = ref(false);
    const edit = ref(false);
    const store       = useStore();
    const loading     = ref<boolean>(false);
    
    const radarData = ref<RadarData>({
      id:"",
      bande : {
        id: "",
        libelle:"",
        debut:undefined,
        fin: undefined,
      },
      largeurBande:undefined,
      bandeAutre : undefined,
      bandeAutreDebut : undefined,
      bandeAutreFin : undefined,
      frequencePorteuse:undefined,
      sites : [
        {
          code: "A",
          nom: "",
          longitude: "",
          latitude: "",
          entGeo : {
            id: ""
          },
        },
        {
          code: "B",
          nom: "",
          longitude: "",
          latitude: "",
          entGeo : {
            id: ""
          },
        }
      ]
    });

    const localiteA = ref(radarData.value.sites[0].entGeo.id);
    const localiteB = ref(radarData.value.sites[1].entGeo.id);
    const localiteAKey = ref(0);
    const localiteBKey = ref(0);
    
    var initialData : any = ref({})

    //******/ COMPUTED PROPERTIES DBT

    const bandes = computed(() => {
      // on retourne le tout
      return getBandeParReseau("radar");
    });

    const title = computed(() => {
          return edit.value ? "Modification d'une ligne du réseau RADAR" : "Ajout d'une ligne au réseau RADAR";
    });

    const actionButtonText = computed(() => {
          return edit.value ? "Modifier" : "Ajouter";
    });
   
    const validationSchema = computed(() => {
      return Yup.object().shape({
        id:Yup.string().notRequired().default(''),
        bande : Yup.object().shape({
          id: Yup.string().required().label("Le type de la bande"),
          debut: Yup.number().required().label("La fréquence"),
          libelle: Yup.string().required().label("Le libellé"),
          fin: Yup.number().required().label("La fréquence"),
        }).label('La bande').typeError('La bande est obligatoire'),
        bandeAutre:Yup.string().notRequired().nullable().label("La bande"),
        largeurBande: Yup.number().positive().required().label("La largeur de la bande"),
        frequencePorteuse: Yup.number().positive().required()
        .when('bande',{
          is : (value) =>  { return (value.id != "" && value.id != undefined);},
          then:(schema) => schema.inclusDansBandeFrequence(radarData.value.bande.debut,radarData.value.bande.fin)
        }).label("La fréquence porteuse"),
        bandeAutreFin: Yup.number().when('bande.id', {
          is: (id) => { return (id == 'xx103b9ef9-bd99-4fdd-8a3d-625325ab75fbxs');},
          then: (schema) => schema.required().positive()
          .when('bandeAutreDebut',{
            is : (value) =>  { return (value != undefined && value !='');},
            then:(schema) => schema.test(
              'bande-autre-fin-pgpp',
              '${path} doit être superieure à celle du début',
              (value, context) => value != undefined && value > context.parent.bandeAutreDebut)
          }),
          otherwise: (schema) => schema.notRequired().nullable(),
        }).label("La fréquence de fin"),
        bandeAutreDebut: Yup.number().when('bande.id', {
          is: (id) => { return (id == 'xx103b9ef9-bd99-4fdd-8a3d-625325ab75fbxs');},
          then: (schema) => schema.required().positive()
          .when('bandeAutreFin',{
            is : (value) =>  { return (value != undefined && value !='');},
            then:(schema) => schema.test(
                'bande-autre-dbt-pgpp',
                '${path} doit être inferieur à celle de fin',
                (value, context) => value != undefined && value < context.parent.bandeAutreFin,
              )
          }),
          otherwise: (schema) => schema.notRequired().nullable(),
        }).label("La fréquence de début de bande"),
        sites : Yup.array().of(Yup.object().shape(
          {
            code: Yup.string().default('A'),
            nom: Yup.string().required().label("Le nom"),
            latitude: Yup.string().latitude().required().label("La latitude"),
            longitude: Yup.string().longitude().required().label("La longitude"),
            entGeo : Yup.object().shape({
             id : Yup.string().required().label("La ville" ),
            })
          }
       )),
      }
    )});

    //******/ COMPUTED PROPERTIES FIN

    const { resetForm, handleSubmit ,errors, setErrors, setFieldValue,setFieldError,values,meta } = useForm<RadarData>({
      validationSchema: validationSchema.value
    });



    onMounted(() => {
      initialData.value = meta.value.initialValues
    })

    const reset = () => {
      // Réinitialisation des données
      resetForm({values: meta.value.initialValues});
      edit.value = false
      showModal.value = false
    };


    const onClose= () => {
      reset()
    };
    
    /**
    * Action initiales réalisées avant l'ouverture
    * du modal
    * @param fh données d'une entrée du réseau fh
    * @returns void()
    */
    const initModal = ( fh : any  = undefined) => 
    {
      edit.value = fh.value == undefined ? false : true
      edit.value ? setDataToForm(fh.value) : setDataToForm(undefined);
      
      localiteA.value = radarData.value.sites[0].entGeo.id;
      localiteB.value = radarData.value.sites[1].entGeo.id;
      localiteAKey.value++;
      localiteBKey.value++;
    }

        /**
    * Initialise les valeurs du schema de validation  et du formulaire
    * lors de l'ajout et de la modification
    * @param entry
    * @returns void()
    */
    const setDataToForm = (entry: any = undefined) => {
      resetForm({ values: setData(entry)});
      radarData.value = {...radarData.value,...values}
      initSelectInput(entry)
      
    }

    /**
    * Actualise les données des inputs de type customisé à l'insertion
    * et à l'édition
    * @param entry
    * @returns void()
    */
     const initSelectInput = (entry) => {
      if(!edit.value)
      {
        radarData.value.sites[1].entGeo.id = ""
        radarData.value.sites[0].entGeo.id = ""
        radarData.value.bande= {
          id: "",
          libelle:"",
          debut:undefined,
          fin: undefined,
        }

      }
      else
      {
        radarData.value.sites[1].entGeo.id = entry.sites[1].entGeo.id
        radarData.value.sites[0].entGeo.id = entry.sites[0].entGeo.id
        radarData.value.bande= {
          id: entry.bande.id,
          libelle:entry.bande.libelle,
          debut:entry.bande.debut,
          fin: entry.bande.fin,
        }
      }
    }

    const submit = handleSubmit( (values) => {
        radarData.value = {...radarData.value, ...values}
        emitter.emit(edit.value ? 'radarEntryUpdated' : 'newRadarEntryAdded', radarData.value);
        showModal.value = false
    });

    const onBandeClear = () => 
    {
      radarData.value.bande ={
        id:"",
        debut:undefined,
        fin:undefined,
        libelle:""
      }
      resetAutreBande()
    }

    const onBandeChange = () => 
    {
      resetAutreBande()
    }

    const resetAutreBande = () => 
    {
      radarData.value.bandeAutreFin = undefined
      radarData.value.bandeAutreDebut = undefined
      var bandeRefAutre = getDefaultBande()
      bandeRefAutre.debut= undefined
      bandeRefAutre.fin = undefined
    }

    watch(() => _.cloneDeep(radarData.value.bandeAutreFin), (currentValue, oldValue) => {
      if(currentValue != undefined )
      radarData.value.bande.fin = Number(currentValue)
     })

    watch(() => _.cloneDeep(radarData.value.bandeAutreDebut), (currentValue, oldValue) => {
      if(currentValue != undefined )
      radarData.value.bande.debut = Number(currentValue)
     })

    return {
      radarData,
      showModal,
      validationSchema,
      title,
      initialData,
      loading,
      meta,
      values,
      errors,
      actionButtonText,
      getBandeDescription,
      bandes,
      villesGabonaises,
      bandeIsAutre,
      getEntgeoDescription,
      onClose,
      open,
      submit,
      initModal,
      onBandeClear,
      onBandeChange,
      localiteA,
      localiteB,
      localiteAKey,
      localiteBKey

    };
  }
});
