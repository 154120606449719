import { defineComponent, computed,onMounted,getCurrentInstance} from "vue";
import { Field, ErrorMessage,useFieldArray } from "vee-validate";
import { useStore } from "vuex";
import RadarListe from "./liste/RadarListe.vue"
import useEmitter from "@/composables/useEmitter";
import { Actions } from "@/store/enums/StoreEnums";


export default defineComponent({
  name: "radar-form",
  props: {
     title : {type:String, required : true},
     subtitle : {type:String, required : true},
     icon : {type:String, required : true}
  },
  components: {
    Field,
    RadarListe,
    ErrorMessage
  },
  setup(props,context) {

    const store   = useStore();
    const emitter = useEmitter();

    /*
    * Permet de recuperer une entité spécifique
    */
    const villes = computed(() => {
      return store.getters.villes;
    });
    
    onMounted(() => {
      store.dispatch(Actions.GET_BANDE_PAR_RESEAU_ACTION, "radar")
    })

    const onNewEntity = (data) => {
      
    };

    onNewEntity
    return {
      villes,
      onNewEntity
    };
  }
});
