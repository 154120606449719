
import { defineComponent } from "vue";
import FooterGuest from './components/Footer.vue';
import Testimonials from './components/Testimonials.vue';
import MenuLanding from './components/MenuLanding.vue'
import LandingHero from './components/LandingHero.vue'
import CommentCelaMarche from './components/CommentCelaMarche.vue'
import GuestDeclaration from './components/GuestDeclaration.vue'
import GuestContact from './components/GuestContact.vue'
import GuestWizard from "@/views/pages/wizards/declarationGuest/DeclarationGuest.vue"
import KTScrollTop from "@/layout/extras/ScrollTop.vue"

export default defineComponent({
  name: "home",
  components: {
   KTScrollTop,
   GuestWizard,
   GuestContact,
   FooterGuest, 
   Testimonials,
   MenuLanding,
   LandingHero, 
   CommentCelaMarche,
   GuestDeclaration
   },
  methods: {}
});
