import { defineComponent, computed,ref,watch,onMounted } from "vue";
import FhListe from "./liste/FhListe.vue"
import { Field, ErrorMessage,useField,useFieldArray } from "vee-validate";
import { useStore } from "vuex";
import { useBande } from "@/composables/useBande";
import { AlertMessage } from "@/bundle/AlertMessage";
    export interface BandeX {
        id: string;
        libelle: string;
        debut: number|undefined;
        fin: number|undefined;
      }

export default defineComponent({
  name: "fh-form",
  props: {
    title : {type:String, required : false},
    subtitle : {type:String, required : false},
    icon : {type:String, required : false}
  },

  setup(props)
  {
    const store = useStore();
    let confirmBandeChange = false
    let bandeHasBeenTouched = ref(false)
    const { getBandeParReseau,bandeIsAutre,getBandeDescription,getDefaultBande}= useBande("fh")
    const localite1 = ref("");
    const localite2 = ref("");
    const typeBande = ref("");
    const fhListe = ref<InstanceType<typeof FhListe>>();
    const bandeAutreDebut = ref(undefined);
    const bandeAutreFin = ref(undefined);

    const { resetField:resetFhBandeId,meta:metaBande } = useField('fh.bande.id');
    const { resetField:resetFhBande,meta:metaBandex,value:fhBande} = useField('fh.bande');


    const bande = ref<BandeX>({
      id:"",
      debut:undefined,
      fin:undefined,
      libelle:""
    });

    onMounted(()=>
    {
      bande.value = fhBande.value as BandeX
    })
    const onBandeClear = () => 
    {
      bandeHasBeenTouched.value = true
      bande.value ={
        id:"",
        debut:undefined,
        fin:undefined,
        libelle:""
      }
      resetAutreBande()
    }

    const onBandeChange = () => 
    {
      resetAutreBande()
    }

    const resetAutreBande = () => 
    {
      bandeAutreFin.value = undefined
      bandeAutreDebut.value = undefined
      var bandeRefAutre = getDefaultBande()
      bandeRefAutre.debut= undefined
      bandeRefAutre.fin = undefined
      bandeHasBeenTouched.value = true

    }

    const villes = computed(() => {
      return store.getters.villes
    })
    const hasEntries = computed(() => {
      return fhListe.value?.hasData
    })
    watch(bandeAutreDebut,(nValue, oValue) => {
      if(nValue != undefined)
      bande.value.debut = Number(nValue)
    })

    watch(bandeAutreFin, (nValue, oValue) => {
      if(nValue != undefined)
      bande.value.fin = Number(nValue)
      
    })

    watch(metaBande, (nValue, oValue) => {
      if(nValue.touched)
      {
        bandeHasBeenTouched.value = true
      }
    })
    watch(bande, (nValue, oValue) => {

       if(!confirmBandeChange && metaBande.dirty && hasEntries.value)
       {
         var options = {
          confirmButtonText: "Confirmer",
          cancelButtonText: "Annuler",
          showCancelButton: true
         }
         var message = "Le changement de bande supprimera les entrées FH que vous avez soumises jusqu'ici. Souhaitez-vous vraiment confirmer le changement?"
         var title  = "Confirmation du changement de bande"
         AlertMessage.onWarning(message,title,options)
        .then((result) => {
          if(result.isConfirmed)
          {
           confirmBandeChange   = false
           fhListe.value?.deleteAllEntries()           
          }
          else
          {
           confirmBandeChange   = true
           bande.value = oValue
          }
        })
       }
       else
       {
        confirmBandeChange   = false
       }
    })
    const bandes = computed(() => {
      return getBandeParReseau("fh")
    })
    return{
      bandes,
      bandeIsAutre,
      bandeAutreDebut,
      getBandeDescription,
      bandeAutreFin,
      bande,
      villes,
      localite1,
      localite2,
      onBandeClear,
      onBandeChange,
      typeBande,
      metaBande,
      fhListe,
      hasEntries,
      bandeHasBeenTouched
    }
  },
  components: {
    Field,
    ErrorMessage,
    FhListe
  }
});
