
import { defineComponent } from "vue";
import ContactForm from './ContactForm.vue'
import ContactAdresse from './ContactAdresse.vue'

export default defineComponent({
  name: "guest-contact",
  components: {
      ContactForm,
      ContactAdresse 
      },
  methods: {}
});
