import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-100" }
const _hoisted_2 = { key: 6 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fh_form = _resolveComponent("fh-form")!
  const _component_vsat_form = _resolveComponent("vsat-form")!
  const _component_pmr_form = _resolveComponent("pmr-form")!
  const _component_gsm_form = _resolveComponent("gsm-form")!
  const _component_blr_form = _resolveComponent("blr-form")!
  const _component_radar_form = _resolveComponent("radar-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isFh)
      ? (_openBlock(), _createBlock(_component_fh_form, {
          key: 0,
          icon: _ctx.showStepLabel.icon,
          title: _ctx.showStepLabel.title,
          subtitle: _ctx.showStepLabel.subtitle
        }, null, 8, ["icon", "title", "subtitle"]))
      : (_ctx.isVsat)
        ? (_openBlock(), _createBlock(_component_vsat_form, {
            key: 1,
            icon: _ctx.showStepLabel.icon,
            title: _ctx.showStepLabel.title,
            subtitle: _ctx.showStepLabel.subtitle
          }, null, 8, ["icon", "title", "subtitle"]))
        : (_ctx.isPmr)
          ? (_openBlock(), _createBlock(_component_pmr_form, {
              key: 2,
              icon: _ctx.showStepLabel.icon,
              title: _ctx.showStepLabel.title,
              subtitle: _ctx.showStepLabel.subtitle
            }, null, 8, ["icon", "title", "subtitle"]))
          : (_ctx.isGsm)
            ? (_openBlock(), _createBlock(_component_gsm_form, {
                key: 3,
                icon: _ctx.showStepLabel.icon,
                title: _ctx.showStepLabel.title,
                subtitle: _ctx.showStepLabel.subtitle
              }, null, 8, ["icon", "title", "subtitle"]))
            : (_ctx.isBlr)
              ? (_openBlock(), _createBlock(_component_blr_form, {
                  key: 4,
                  icon: _ctx.showStepLabel.icon,
                  title: _ctx.showStepLabel.title,
                  subtitle: _ctx.showStepLabel.subtitle
                }, null, 8, ["icon", "title", "subtitle"]))
              : (_ctx.isRadar)
                ? (_openBlock(), _createBlock(_component_radar_form, {
                    key: 5,
                    icon: _ctx.showStepLabel.icon,
                    title: _ctx.showStepLabel.title,
                    subtitle: _ctx.showStepLabel.subtitle
                  }, null, 8, ["icon", "title", "subtitle"]))
                : (_openBlock(), _createElementBlock("div", _hoisted_2, " Le type de réseau sélection est inconnu "))
  ]))
}