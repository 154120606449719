import { defineComponent, ref, computed, watch,getCurrentInstance,onMounted } from "vue";
import { useStore } from "vuex";
import _ from "lodash";
import Yup from "@/bundle/validations/YupExpended";
import { ErrorMessage, Field, useForm, useField} from "vee-validate";
import { v4 as uuidv4 } from 'uuid';

interface FhData {
   id?:string;
   bande:{
    debut:number|undefined;
    fin:number|undefined;
   };
   frqA:number|undefined;
   frqB:number|undefined;
   siteA : Site;
   siteB : Site;
   ecartDuplex: number|undefined;
   largeurBande: number|undefined;
   largeurUnique: number|undefined;
   longueurBond: number|undefined;
}
interface sendFhData {
  id?:number|undefined;
  frqA:number|undefined;
  frqB:number|undefined;
  siteA : Site;
  siteB : Site;
  ecartDuplex: number|undefined;
  largeurBande: number|undefined;
  largeurUnique: number|undefined;
  longueurBond: number|undefined;
}
interface Bande {
  createdDate: Date|string|undefined;
  lastModifiedDate: Date|string|undefined;
  id: string| undefined;
  libelle: string;
  debut: number;
  fin: number;
}
interface Site {
  code: string;
  nom: string;
  longitude: string;
  latitude: string;
  entGeo : {
    id:string;
  };
}

interface sendfhData {
  id?:number|undefined;
  frqA:number|undefined
  frqB:number|undefined
  siteA : Site,
  siteB : Site
}

interface EntGeo {
  id: string;

}
import useEmitter from '@/composables/useEmitter'
import { useBande } from "@/composables/useBande";
import { useEntGeos } from "@/composables/useEntGeos";
export interface BandeX {
  id: string;
  libelle: string;
  debut: number|undefined;
  fin: number|undefined;
}
export default defineComponent({
  name: "new-fh-modal",
  props:{
    bande : {type:Object,default : {} as BandeX},
  },
  components: {ErrorMessage, Field},
  setup(props,context) {

    //******/ DONNEES DBT
    const emitter = useEmitter();
    const {getBandeDescription} = useBande("fh");
    const {villesGabonaises,getEntgeoDescription} = useEntGeos();


    const showModal = ref(false);
    
    const siteA = ref('')
    const siteB = ref('')
    const edit = ref(false);
    const loading     = ref<boolean>(false);
    const localiteAKey = ref(0);
    const localiteBKey = ref(0);
    
    const fhData = ref<FhData>({
      id:"",
      bande:{
        debut:props.bande.debut,
        fin:props.bande.fin,
      },
      ecartDuplex: undefined,
      largeurBande: undefined,
      largeurUnique: 0,
      longueurBond:undefined,
      frqA:undefined,
      frqB:undefined,
      siteA : 
        {
          code: "A",
          nom: "",
          longitude: "",
          latitude: "",
          entGeo : {
            id: ""
          },
        },
      siteB:
        {
          code: "B",
          nom: "",
          longitude: "",
          latitude: "",
          entGeo : {
            id: ""
          },
        }
      
    });
    //******/ DONNEES FIN

    //******/ COMPUTED PROPERTIES DBT

    // Description de la bande selectionnée
    const currentBande = computed(() => {
      // on retourne le tout
      return getBandeDescription(props.bande as BandeX);
    });

    // Titre du modal
    const title = computed(() => {
          return edit.value ? "Modification d'une ligne au réseau FH" : "Ajout d'une ligne au réseau FH";
    });

    // Titre du boutton d'action
    const actionButtonText = computed(() => {
          return edit.value ? "Modifier" : "Ajouter";
    });

    // Schema de validation du formulaire
    const validationSchema = computed(() => {
      return Yup.object().shape({
        id:Yup.string().notRequired().default(''),
        bande : Yup.object().shape({
          debut: Yup.number().required().label("La fréquence").default(props.bande.debut),
          fin: Yup.number().required().label("La fréquence"),
        }),
        ecartDuplex: Yup.number().required().label("L'écart duplex"),
        longueurBond: Yup.number().positive().required().label("La longueur de bond"),
        largeurBande: Yup.number().notRequired().label("La largeur de la bande"),
        largeurUnique: Yup.number().required().label("La largeur").default(0),
        frqA: Yup.number().required().positive().when('bande.debut',{
          is: (value) => value != undefined,
          then: (schema) => schema.inclusDansBandeFrequence(fhData.value.bande.debut,fhData.value.bande.fin)
        }).label("La fréquence A"),
        frqB: Yup.number().required().positive().when('bande.fin',{
          is: (value) => value != undefined,
          then: (schema) =>schema.inclusDansBandeFrequence(fhData.value.bande.debut,fhData.value.bande.fin)
        }).label("La fréquence B"),
        siteA : Yup.object().shape(
          {
            code: Yup.string().default('A'),
            nom: Yup.string().required().label("Le nom"),
            latitude: Yup.string().latitude().required().label("La latitude"),
            longitude: Yup.string().longitude().required().label("La longitude"),
            entGeo : Yup.object().shape({
             id : Yup.string().required().label("La ville" ),
            })
          }
       ),
       siteB : Yup.object().shape(
        {
          code: Yup.string().default('B'),
          nom: Yup.string().required().label("Le nom"),
          latitude: Yup.string().latitude().required().label("La latitude"),
          longitude: Yup.string().longitude().required().label("La longitude"),
          entGeo : Yup.object().shape({
           id : Yup.string().required().label("La ville" ),
          })
        }
     ),
      }
    )});

    //******/ COMPUTED PROPERTIES FIN

    onMounted(() => {
      
    })

    const { resetForm, handleSubmit ,errors, values, meta} = useForm<FhData>({
      validationSchema: validationSchema.value
    });

    /**
    * Réinitialisation des données du formulaire
    * @returns void()
    */

    const reset = () => {
      // Réinitialisation des données
      resetForm({values: meta.value.initialValues});
      edit.value = false
      showModal.value = false
    };

    /**
    * Actions à faire à la fermeture du modal
    * @returns void()
    */
    const onClose= () => {
      reset()
    };

   /**
    * Action initiales réalisées avant l'ouverture
    * du modal
    * @param fh données d'une entrée du réseau fh
    * @returns void()
    */
    const initModal = ( fh : any  = undefined) => 
    {
      edit.value = fh.value == undefined ? false : true
      edit.value ? setDataToForm(fh.value) : setDataToForm(undefined);
      localiteAKey.value++;
      localiteBKey.value++;
    }

    /**
    * Soumission du formulaire
    * @param values
    * @returns void()
    */
    const submit = handleSubmit((values) => 
    {
        
        const   {bande , ...sendData} = values
        emitter.emit(edit.value ? 'fhEntryUpdated' : 'newFhEntryAdded', sendData);
        showModal.value = false
    });

    /**
    * Actualise les données des inputs de type customisé à l'insertion
    * et à l'édition
    * @param entry
    * @returns void()
    */
    const initSelectInput = (entry) => {
      if(!edit.value)
      {
        fhData.value.siteA.entGeo.id = ""
        fhData.value.siteB.entGeo.id = ""
        fhData.value.bande.debut = props.bande.debut
        fhData.value.bande.fin = props.bande.fin
      }
      else
      {
        fhData.value.siteA.entGeo.id = entry.siteB.entGeo.id
        fhData.value.siteB.entGeo.id = entry.siteB.entGeo.id
        
      }
    }

    /**
    * Initialise les valeurs du schema de validation  et du formulaire
    * lors de l'ajout et de la modification
    * @param entry
    * @returns void()
    */
    const setDataToForm = (entry: any = undefined) => {

      resetForm({
        values: {
          id: entry != undefined ? entry.id : uuidv4(),
          bande :
          {
            debut: props.bande.debut,
            fin: props.bande.fin,
          },
          ecartDuplex: entry != undefined ? entry.ecartDuplex : undefined,
          largeurBande: entry != undefined ? entry.largeurBande : undefined,
          largeurUnique: entry != undefined ? entry.largeurUnique : undefined,
          longueurBond: entry != undefined ? entry.longueurBond : undefined,
          frqA: entry != undefined ? entry.frqA : undefined,
          frqB: entry != undefined ? entry.frqB : undefined,
          siteA: {
            code: entry != undefined ?  entry.siteA.code : "A" ,
            nom: entry != undefined ?  entry.siteA.nom  : "",
            longitude: entry != undefined ?  entry.siteA.longitude  : "",
            latitude: entry != undefined ?  entry.siteA.latitude  : "",
            entGeo: {
              id: entry != undefined ?  entry.siteA.entGeo.id  : ""
            }
          },
          siteB: {
            code: entry != undefined ?  entry.siteB.code  : "B",
            nom: entry != undefined ?  entry.siteB.nom  : "",
            longitude: entry != undefined ?  entry.siteB.longitude  : "",
            latitude: entry != undefined ?  entry.siteB.latitude  : "",
            entGeo: {
              id: entry != undefined ?  entry.siteB.entGeo.id  : ""
            }
          }
        }
      });
      
      initSelectInput(entry)
      
    }

    const updateLargeurBande = (e) => {
      fhData.value.largeurBande = (fhData.value.largeurUnique as number) * 2;      
    }

    return {
      fhData,
      showModal,
      validationSchema,
      title,
      loading,
      meta,
      values,
      errors,
      actionButtonText,
      currentBande,
      villesGabonaises,
      onClose,
      initModal,
      submit,
      getEntgeoDescription,
      updateLargeurBande,
      siteA,
      siteB,
      localiteAKey,
      localiteBKey
    };
  }
});
