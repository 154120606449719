
import { defineComponent } from "vue";
import { Field, ErrorMessage, FieldArray, useFieldArray } from "vee-validate";

export default defineComponent({
  name: "step-2",
  components: {
    Field,
    ErrorMessage,
    FieldArray 
  },
  setup() {

    const { remove, push, fields } = useFieldArray('responsableTechniques');
    
    const removeRp = (index) =>
    {
      if(fields.value.length>1)
      remove(index)
    }

    const addRp = () =>
    {
      if(fields.value.length<3)
      push({ email: '', nom: '',prenom:'', password:'' })
    }
    return {
      fields,
      addRp,
      removeRp,
    };
  }
});
