<template>
      <!--begin::Footer Section-->
    <div class="mb-0">
        <!--begin::Wrapper-->
        <div class="landing-dark-bg">
            <!--begin::Separator-->
            <div class="landing-dark-separator"></div>
            <!--end::Separator-->
            <!--begin::Container-->
            <div class="container">
                <!--begin::Wrapper-->
                <div class="d-flex flex-column flex-md-row flex-stack py-7 py-lg-10">
                    <!--begin::Copyright-->
                    <div class="d-flex align-items-center order-2 order-md-1">
                        <!--begin::Logo-->
                        <a href="https://aninf.ga/" target="_blank">
                            <img alt="Logo" src="media/logos/aninf.png" class="" style="width: 80px;"/>
                        </a>
                        <!--end::Logo image-->
                        <!--begin::Logo image-->
                        <span class="mx-5 fs-6 fw-bold text-gray-600 pt-1" >© {{currentYear}} Tous droits réservés.</span>
                        <!--end::Logo image-->
                    </div>
                    <!--end::Copyright-->
                    <!--begin::Menu-->
                    <ul class="menu menu-gray-600 menu-hover-primary fw-bold fs-6 fs-md-5 order-1 mb-5 mb-md-0">
                        <li class="menu-item">
                                <a href="#" class="mb-6">
                                    <img src="media/svg/brand-logos/facebook-4.svg" class="h-20px me-2" alt="" />
                                    <span class="text-white opacity-50 text-hover-primary fs-5 mb-6">Facebook</span>
                                </a>
                        </li>
                        <li class="menu-item mx-5">
                                <a href="#" class="mb-6">
                                    <img src="media/svg/brand-logos/twitter.svg" class="h-20px me-2" alt="" />
                                    <span class="text-white opacity-50 text-hover-primary fs-5 mb-6">Twitter</span>
                                </a>
                        </li>
                    </ul>
                    <!--end::Menu-->
                </div>
                <!--end::Wrapper-->
            </div>
            <!--end::Container-->
        </div>
        <!--end::Wrapper-->
    </div>
    <!--end::Footer Section-->
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "KTFooter",
  setup() {
    const currentYear = new Date().getFullYear();

    return {
      currentYear
    };
  },
});
</script>

<style>

</style>