

import { TypeBwwr, Code } from "../enum/enumeration";
import { Bande } from "@/core/models/Bande";
import { Site } from "@/core/models/Site";
import * as Yup from "yup";

interface Reseau{
  bande: {
    id : string
  }
  frequencePorteurse: number|undefined;
  bandeAutre : string|undefined|null;
  largeurBande: number|undefined;
  sites: Array<Site>;
}

export class StepRadar { 
    //field 
    
    lignes: Array<Reseau>;
    observation: string|undefined;
    numeroAutorisation: string|undefined;

    //constructor 
    constructor() { 
        this.lignes = []
        this.numeroAutorisation = undefined
        this.observation = ""
    }  

    static validationSchema() {

        return       Yup.object({
            numeroLicence: Yup.string().label("Le n° de licence"),
            radar: Yup.object().shape
            ({
              numeroAutorisation: Yup.string().required().label("Le n° d'autorisation"),
              observation: Yup.string().notRequired().label("L'observation'"),
              lignes : Yup.array().of(
                Yup.object().shape({
                bande : Yup.object().shape({
                  id: Yup.string().required().label("Le nom"),
                }),
                bandeAutre: Yup.string().nullable().label('La latitude'),
                frequencePorteuse: Yup.number().positive().required().label("La fréquence porteuse"),
                largeurBande: Yup.number().positive().required().label('la largeur de bande'),
                sites : Yup.array().of(
                  Yup.object().shape({
                    code: Yup.string().required().oneOf(['A','B']).label("Le code du site"),
                    nom: Yup.string().required().label("Le nom du site"),
                    latitude: Yup.string().latitude().required().label('La latitude'),
                    longitude: Yup.string().longitude().required().label('La longitude'),
                    entGeo: Yup.object().shape({
                      id: Yup.string().required().label("La localité"),
                    }),
                  })
                ).min(2).label('Les sites')
                })
              ).min(1).label('Les lignes du réseau Radar'),
            })
      })
    }
 }