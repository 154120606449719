
import { defineComponent,ref,computed, inject } from "vue";
import { Field, ErrorMessage, useFieldArray,FieldArray,useField} from "vee-validate";
import { string } from "yup";

interface Document {
  type: string;
  size: number|undefined;
  name: string;
  uri: String|any
}

export default defineComponent({
  name: "step-5",
  props:{
      currentStep : {type:Boolean, default:false},
      errors : {type:Object, default:{}}
  },
  components: {
    Field,
    ErrorMessage,
    FieldArray
  },
  setup(props) {

    const MAX_DOCUMENTS = ref<number>(4)
    const {update, fields} = useFieldArray('documents');



    
    const currentErrors = (index)=> {
    }

    const onChange = (e : any, r : any,index : number) => 
    {
      if (e.target.files && e.target.files[0]) 
      {
        var newDoc = <Document>{
          uri: "",
          type:'',
          size:undefined,
          name:""
          } 
        var reader = new FileReader();

        reader.onload = function (ex) 
        {
            newDoc.uri = ex.target?.result
            update(index, newDoc);
        }

        // Assignation des données
        newDoc.name = e.target.files[0].name
        var types = e.target.files[0].name.split('.')
        newDoc.size =  e.target.files[0].size/(1024*1024)
        if(types.length > 0)
          newDoc.type = types[types.length -1]
        else
          newDoc.type = "";
        
        // on transforme en base 64
        reader.readAsDataURL(e.target.files[0]);
      }
    }

    const findErrors = (e : string) => 
    {


       var kp : string | undefined =  undefined
      Object.keys(props.errors as any).forEach(function(key,index) {
            // key: the name of the object key
            // index: the ordinal position of the key within the object 
            if(key.startsWith(e+'.') && kp==undefined)
            {
              kp = key
              return
            }
        });

      return kp != undefined ? props.errors[kp] : "";
    }
    const erreurs = computed (() => {

      return Object.keys(props.errors as {})
    })

    return {
      onChange ,
      erreurs,
      fields,
      currentErrors,
      findErrors,
      ErrorMessage,
      MAX_DOCUMENTS,
    };
  },
});
