
import type { ElTable } from 'element-plus'
import NewFhModal from "@/components/modals/forms/newFhModal/NewFhModal.vue"

import { defineComponent, computed,onMounted,ref} from "vue";
import { Field, ErrorMessage,useFieldArray,useField } from "vee-validate";
import useEmitter from "@/composables/useEmitter";
import {useEntGeos} from "@/composables/useEntGeos";
import {useBande} from "@/composables/useBande";
import { AlertMessage } from '@/bundle/AlertMessage';
import _ from 'lodash';

    export interface Bande {
        id: string;
    }
    export interface BandeX {
        id: string;
        libelle: string;
        debut: number;
        fin: number;
      }
    export interface EntGeo {
        id: string;
    }

    export interface Site {
        code: string;
        nom: string;
        longitude: string;
        latitude: string;
        entGeo: EntGeo;
    }

    export interface Entry {
        id : string;
        bande: Bande;
        largeurBande: string;
        bandeAutre: string;
        frequencePorteuse: string;
        siteA: Site;
        siteB: Site;
    }

    export interface EntryWithoutKey {
        bande: Bande;
        largeurBande: string;
        bandeAutre: string;
        frequencePorteuse: string;
        sites: Site[];
    }
export default defineComponent({
  name: "fh-liste",
  props: {
     bande : {type:Object,default : {} as BandeX},
     showAction : {type:Boolean, default : true},
  },
  components: {
    Field,
    ErrorMessage,
    NewFhModal,
  },
  setup(props, context) {

    const emitter = useEmitter();
    const {getVilleDescription} = useEntGeos();
    const {getBandeDescription, bandeLimitIsSet, bandeIsAutre} = useBande("fh");
    const fhModal = ref<InstanceType<typeof NewFhModal>>();

    const { remove, push,update, fields,replace } = useFieldArray('fh.lignes');
    const { resetField:resetFhLignes,validate } = useField('fh.lignes',value => !!value);
    


    onMounted(()=>{
        // set the field as valid and clears errors
        resetFhLignes();
       // resetFhBandeId();
    })

    const multipleTableRef = ref<InstanceType<typeof ElTable>>()
    const multipleSelection = ref<Entry[]>([])

    // on écoute l'évenement d'enregistrement de l'ajout d'une entrée FH
    emitter.on('newFhEntryAdded', (entry) => {
      //On ajoute la nouvelle entrée dans la liste
      push(entry)
    })

    // on écoute l'évenement de la modification d'une entrée FH
    emitter.on('fhEntryUpdated', (entry) => {
      var currentField = fields.value.find((item,index) => {
        //Si un objet à la même clé que notre entrée
        var it : any = item?.value
        return it?.hasOwnProperty('id') && it?.id == entry?.id
      })

      if(currentField) update(currentField.key as number,entry)
      
    })

    const hasData = computed(() => {
      return fields.value.length > 0
    })

    const canAddEntry = computed(() => {
      var message = "Vous devez choisir une bande de fréquence avant d'ajouter une entrée du réseau FH"
      if( bandeIsAutre(props.bande.id))
      {
        if(props.bande.debut != undefined && props.bande.fin != undefined )
        {
          message = ""
          return {result:true,message:message}
        }
        else
        {
         message = "Vous devez préciser la début et la fin de votre brande de fréquence Autre avant d'ajouter une entrée FH"
         return {result:false,message:message}
        }

      }
      return {result:bandeLimitIsSet(props.bande),message:message}
    })

    const data = computed(() => {
      let data :any = []
      fields.value.forEach(item => {
        data.push({ ...{}, ...item.value as any})
      })
      return data
    })
    

    const openModal = (entry:Entry|undefined = undefined) => {
      if(canAddEntry.value?.result)
      {
          fhModal.value!.$vfm.show('fhModal',entry)
      }
      else
      AlertMessage.onWarning(canAddEntry.value?.message)
   }

    const hasItemSelected = computed(() => {
      return multipleSelection.value.length > 0;
    })

    const removeItem = (index) => {
      remove(index)
    }

    const deleteAllEntries = () => {
      replace([])
    }

    const clearSelection = (rows?: Entry[]) => {
        multipleTableRef.value!.clearSelection()
    }
    
    const deleteSelection = (rows?: Entry[]) => {
        var message = "Souhaitez vous supprimer les entrées de FH que vous avez introduites.<br/> <strong></strong>"
        message += "<strong>N.B :</strong> Les informations supprimées seront définitivement perdues."
        var title =  "Confirmation de suppréssion"
        var options = {showCancelButton:true}

        AlertMessage.onWarning(message,title,options).then((d) => {
          /* Read more about isConfirmed, isDenied below */
          if(d.isConfirmed){
            // Effacez la selection
            // on recupere tous les ids de la selection
            var indexes : Array<string> = _.map(multipleSelection.value, 'id');
  
            //On parcourt la liste des ids
            indexes.forEach((id,index) => 
            {
              var index : number = 0
              // on parcourt la liste des lignes
              for (let v of fields.value) 
              {
                var it : any = v?.value
                //Si les ids correspondent
                if(it.id == id)
                {
                  //on retire la ligne concernée
                  remove(index);
                  multipleSelection.value = multipleSelection.value.filter(s  => { return s.id != id})
                  // on brise cette boucle
                  break;
                }
                // on incrémente l'index
                index++;
              }
            })
          }
        })
    }

    const handleSelectionChange = (val: Entry[]) => {
      multipleSelection.value = val
    }

    return {
      hasData,
      data,
      multipleTableRef,
      fields,
      openModal,
      clearSelection,
      getVilleDescription,
      handleSelectionChange,
      getBandeDescription,
      deleteSelection,
      multipleSelection,
      hasItemSelected,
      update,
      fhModal,
      canAddEntry,
      removeItem,
      deleteAllEntries
    };
  }
})