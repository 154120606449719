
import { defineComponent, computed, ref} from "vue";
import FhRecap from "@/components/networks/fh/recap/FhRecap.vue";
import VsatRecap from "@/components/networks/vsat/VsatRecap.vue";
import PmrRecap from "@/components/networks/pmr/recap/PmrRecap.vue";
import GsmRecap from "@/components/networks/gsm/recap/GsmRecap.vue";
import BlrRecap from "@/components/networks/blr/recap/BlrRecap.vue";
import RadarRecap from "@/components/networks/radar/recap/RadarRecap.vue";
import { Field, ErrorMessage,useField} from "vee-validate";
import { useEntGeos } from "@/composables/useEntGeos";

export default defineComponent({
  name: "step-6",
  props: {
     data : {type:Object, default:null},
     currentStep : {type:Boolean, default:false}
  },
  setup(props){
    

    const {getVilleDescription} = useEntGeos();
    const showPassword = ref(false)
    const confirmed = ref(false)


    const hiddenPassword= computed(() => 
    {
       var newValue : string = ""

       for (var i = 0; i < props.data.permissionnaire.password.length; i++) {
         newValue+="*";
        }
       return newValue
    })

    const isFh= computed(() => {return props.data.typeReseau == 'FH' ?  true : false})
    const isVsat= computed(() => {return props.data.typeReseau == 'VSAT' ?  true : false})
    const isPmr= computed(() => {return props.data.typeReseau == 'PMR' ?  true : false})
    const isGsm= computed(() => {return props.data.typeReseau == 'GSM' ?  true : false})
    const isBlr= computed(() => {return props.data.typeReseau == 'BLR' ?  true : false})
    const isRadar= computed(() => {return props.data.typeReseau == 'RADAR' ?  true : false})
    
    return{
      isFh,
      isVsat,
      isPmr,
      isGsm,
      isBlr,
      showPassword,
      hiddenPassword,
      confirmed,
      isRadar,
      getVilleDescription
      
    }
  },
  components: {FhRecap,Field, ErrorMessage,VsatRecap,PmrRecap,GsmRecap,BlrRecap,RadarRecap},
});
