<template>
    <div class="d-lg-block" id="kt_header_nav_wrapper">
    <div
        class="d-lg-block p-5 p-lg-0"
        data-kt-drawer="true"
        data-kt-drawer-name="landing-menu"
        data-kt-drawer-activate="{default: true, lg: false}"
        data-kt-drawer-overlay="true"
        data-kt-drawer-width="200px"
        data-kt-drawer-direction="start"
        data-kt-drawer-toggle="#kt_landing_menu_toggle"
        data-kt-swapper="true"
        data-kt-swapper-mode="prepend"
        data-kt-swapper-parent="{default: '#accueil', lg: '#kt_header_nav_wrapper'}"

    >
        <!--begin::Menu-->
        <div class="menu menu-column flex-nowrap menu-rounded menu-lg-row menu-title-gray-500 menu-state-title-primary nav nav-flush fs-5 fw-bold" id="kt_landing_menu">
            <!--begin::Menu item-->
            <div class="menu-item">
                <!--begin::Menu link-->
                <router-link data-kt-scroll-toggle="true" data-kt-drawer-dismiss="true" class="menu-link nav-link py-3 px-4 px-xxl-6" router-link-active :to="{ path: '/', hash: '#accueil' }" >Accueil</router-link>
                <!--end::Menu link-->
            </div>
            <!--end::Menu item-->
             <!--begin::Menu item-->
            <div class="menu-item">
                <!--begin::Menu link-->
                <router-link data-kt-scroll-toggle="true" data-kt-drawer-dismiss="true" class="menu-link nav-link py-3 px-4 px-xxl-6" :to="{ path: '/', hash: '#comment-cela-marche' }"  >Comment ça marche ?</router-link>
                <!-- <a class="menu-link nav-link py-3 px-4 px-xxl-6" href="#comment-cela-marche" data-kt-scroll-toggle="true" data-kt-drawer-dismiss="true">How it Works</a> -->
                <!--end::Menu link-->
            </div>
            <!--end::Menu item-->
            <!--begin::Menu item-->
            <div class="menu-item">
                <!--begin::Menu link-->
                <router-link data-kt-scroll-toggle="true" data-kt-drawer-dismiss="true" class="menu-link nav-link py-3 px-4 px-xxl-6"  :to="{ path: '/', hash: '#premiere-declaration' }">Première déclaration</router-link>
                <!--end::Menu link-->
            </div>
            <!--end::Menu item-->
            <!--begin::Menu item-->
            <div class="menu-item">
                <!--begin::Menu link-->
                <router-link data-kt-scroll-toggle="true" data-kt-drawer-dismiss="true" class="menu-link nav-link py-3 px-4 px-xxl-6"  :to="{ path: '/', hash: '#contact' }" >Contact</router-link>
                <!--end::Menu link-->
            </div>
            <!--end::Menu item-->
        </div>
        <!--end::Menu-->
    </div>
</div>
</template>

<script>

export default {

}
</script>

<style>

</style>