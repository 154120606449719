import { computed, defineComponent, provide, onMounted, ref,markRaw} from "vue";
import { IScrollTopOptions, StepperComponent } from "@/assets/ts/components";
import { useForm } from "vee-validate";
import { Path} from "@/router/enums/RouterEnums";
import {  useRouter } from "vue-router";
import {AlertMessage} from "@/bundle/AlertMessage"
import ApiService from "@/core/services/ApiService"
import Yup from "@/bundle/validations/YupExpended";
import Step1 from "@/views/pages/wizards/declarationGuest/steps/Step1.vue";
import Step2 from "@/views/pages/wizards/declarationGuest/steps/Step2.vue";
import Step3 from "@/views/pages/wizards/declarationGuest/steps/Step3.vue";
import Step4 from "@/views/pages/wizards/declarationGuest/steps/Step4.vue";
import Step5 from "@/views/pages/wizards/declarationGuest/steps/Step5.vue";
import Step6 from "@/views/pages/wizards/declarationGuest/steps/Step6.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { StepFH } from "@/core/models/guest/StepFH";
import { StepVsat } from "@/core/models/guest/StepVsat";
import { StepPmr } from "@/core/models/guest/StepPmr";
import { StepGsm } from "@/core/models/guest/StepGsm";
import { StepRadar } from "@/core/models/guest/StepRadar";
import { StepBlr } from "@/core/models/guest/StepBlr";
import { RegexList } from "@/bundle/RegexList";
import { TypeDocument } from "@/core/models/TypeDocument";
import {ACCEPTED_FILETYPE, ACCEPTED_FILETYPE_MESSAGE, TypeEnregistrement} from "@/core/models/enum/enumeration";
import { useBande } from "@/composables/useBande";
import { useSousBande } from "@/composables/useSousBande";
import { ElementAnimateUtil } from "@/assets/ts/_utils";


export interface User {
  name: string;
  surname: string;
  email: string;
  password: string;
  token: string;
}
interface Document {
  type: string;
  typeDocument:TypeDocument|null;
  size: number;
  name: string;
  uri: String|any
}


interface IStep1 {
  exercice : string| undefined
  enregistrement_sans_declaration:TypeEnregistrement|undefined,
 permissionnaire : {
     raisonSociale: string;
     boitePostale: string;
     telephone: string;
     email: string;
     password: string;
     confirmPassword: string;
     entGeo:{
      id: string;
     };
 }
}

interface IStep2 {
 responsableTechniques : [
   {
     nom: string;
     prenom: string;
     email: string;
     telephone: string;
     telephone2: string;
     fonction: string;
     permissionnaire: undefined
 }
 ]
}

interface IStep3 {
  typeReseau: string;
}

interface IStep4 {
  numeroLicence: string;
  fh ?: StepFH;
  vsat ?: StepVsat;
  pmr ?: StepPmr;
  gsm ?: StepGsm;
  radar ?: StepRadar;
  blr ?: StepBlr;
}

interface IStep5 {
  documents: Array<Document>;
}

interface IStep6 {
  confirmed: boolean;
}

interface CreateAccount extends IStep1, IStep2, IStep3, IStep4, IStep5, IStep6 {}

// @ts-ignore
export default defineComponent({
  name: "guest-wizard",
  computed: {
    TypeEnregistrement() {
      return TypeEnregistrement
    }
  },
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    Step6
  },
  
  setup() {

    const _stepperObj = ref<StepperComponent | null>(null);
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const nextButtonRef = ref<null | HTMLButtonElement>(null);
    const horizontalWizardRef = ref<HTMLElement | null>(null);
    const currentStepIndex = ref(0);

    const router = useRouter()
    const {bandeIsAutre} = useBande()
    const {sousBandeIsAutre} = useSousBande()
    const formKey = ref(0);

    const formData = ref<CreateAccount>({
      documents:[],
      confirmed : false,
      permissionnaire : {
        raisonSociale:"",
        boitePostale:"",
        telephone: "", 
        email:"",
        password:"",
        confirmPassword:"",
        entGeo : {
          id:""
        }
      },
      responsableTechniques : [
        {
        nom:"",
        prenom:"",
        email: "",
        telephone:"",
        telephone2:"",
        fonction:"",
        permissionnaire:undefined
      }
      ],
      typeReseau: "",
      exercice:undefined,
      enregistrement_sans_declaration:undefined,
      numeroLicence:"",
      fh : new StepFH(),
      vsat : new StepVsat(),
      pmr : new StepPmr(),
      gsm : new StepGsm(),
      blr : new StepBlr(),
      radar : new StepRadar()
    });

    onMounted(() => {
      _stepperObj.value = StepperComponent.createInsance(
        horizontalWizardRef.value as HTMLElement
      );
      setCurrentPageBreadcrumbs("Horizontal", ["Pages", "Wizards"]);
    });
    // L'ensemble des schemas de validation
    let createNetworkSchema = [
      //Schema Permissionnaire
      {
        index : 0,
        schema:   Yup.object(
          {
          exercice : Yup.string().min(4).max(4).required().label("L'année d'exercice"),
            enregistrement_sans_declaration: Yup.string().required().oneOf([TypeEnregistrement.SANS_DECLARATION, TypeEnregistrement.AVEC_DECLARATION]).label("Le type d'enregistrement"),
            permissionnaire : Yup.object().shape({
          raisonSociale : Yup.string().min(2).max(60).required().label("La raison sociale"),
          boitePostale : Yup.string().matches(RegexList.BOITE_POSTALE,'La boite postale ne doit contenir que des chiffres').max(5,"La boite postale ne doit contenir au plus que 5 chiffres").required().label("La boite postale"),
          telephone : Yup.string().numerotationGabonaise().required().label("Le numéro de téléphone"),

          email : Yup.string().required().email().label("L'adresse e-mail"),
          entGeo: Yup.object().shape({
            id : Yup.string().required().label("La ville "),
          }),
          password : Yup.string().noWhiteSpace().min(8).required().label("Le mot de passe"),
          confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Le mot de passe et la confirmation ne correspondent pas')
        }
        )})
      },
     //Schema Responsable Technique
     {
      index : 1,
      schema:    Yup.object({responsableTechniques : Yup.array().of(
        Yup.object().shape({
          nom : Yup.string().required().min(2).max(60).label("Le nom"),
          prenom : Yup.string().required().min(2).max(60).label("Le prénom"),
          email : Yup.string().required().email().label("L'adresse e-mail"),
          telephone : Yup.string().numerotationGabonaise().required().label("Le numéro de téléphone"),
          telephone2 : Yup.string().notRequired().numerotationGabonaise().label("Le numéro de téléphone"),
          fonction : Yup.string().label("La fonction"),
        })
      )})
     },
      // Schema Type Reseau
     {
      index : 2,
      schema: Yup.object({typeReseau: Yup.string().required().label("Le type de réseau"),}),
     },
     // Schema Details réseaux
      {
        index:3,
        schema:{
          fh:StepFH.validationSchema(),
          // Schema Vsat
          vsat:StepVsat.validationSchema(),
          // Schema Pmr
          pmr:StepPmr.validationSchema(),
          // Schema GSM
          gsm:StepGsm.validationSchema(),
          // Schema RADAR
          radar:StepRadar.validationSchema(),
          // Schema RADAR
          blr:StepBlr.validationSchema(),
        }
      },
      // Schema documents
      {
        index : 4,
        schema:    Yup.object({documents : Yup.array().of(
          Yup.object().shape({
            uri :  Yup.string().matches(RegexList.BASE64_FORMAT,'Le document est invalide.').required().label("Le document"),
            size : Yup.number().nullable().lessThan(2,'Le document ne doit pas exceder 2 MB').label("La taille du document"),
            type : Yup.string().nullable().required().oneOf(Object.values(ACCEPTED_FILETYPE), ACCEPTED_FILETYPE_MESSAGE.TYPE).label('Le document'),
          })
        ).min(0).max(4)})
       },
      //  // Schema Recapitulatif
       {
        index : 5,
        schema: Yup.object({confirmed: Yup.bool().required().equals([true],"La confirmation des données est obligatoire").label("La confirmation des données"),})
       }
    ];
    
    // Schema de validation courant
    const currentSchema = computed(() => {
      return currentStepIndex.value == 3 ? markRaw(createNetworkSchema[currentStepIndex.value]?.schema[formData.value.typeReseau.toLowerCase()])
                                         : markRaw(createNetworkSchema[currentStepIndex.value]?.schema)
    });


    const { resetForm, handleSubmit, errors,setErrors,meta } = useForm<IStep1 | IStep2 | IStep3 | IStep4 | IStep5 | IStep6>({
      validationSchema: currentSchema,
    });

    // On rend le tableau des erreurs
    // accessible pour tous les composants
    provide('errors', errors.value)

    // Les étapes totales du wizard
    const totalSteps = computed(() => {
      if (!_stepperObj.value) {
        return;
      }
      return _stepperObj.value.totatStepsNumber;
    });

    // Vérifie si le wizard est à la fin
    const wizardAtTheEnd = computed(() => {
      var total = (totalSteps.value) ? totalSteps.value : 0
      return currentStepIndex.value == (total - 1);
    });


    resetForm({
      values: {
        ...formData.value,
      },
    });

    const initForm = () => {
      formData.value = {
        documents:[],
        confirmed : false,
        permissionnaire : {
          raisonSociale:"",
          boitePostale:"",
          telephone: "", 
          email:"",
          password:"",
          confirmPassword:"",
          entGeo : {
            id:""
          }
        },
        responsableTechniques : [
          {
          nom:"",
          prenom:"",
          email: "",
          telephone:"",
          telephone2:"",
          fonction:"",
          permissionnaire:undefined
        }
        ],
        typeReseau: "",
        exercice:undefined,
        enregistrement_sans_declaration:undefined,
        numeroLicence:"",
        fh : new StepFH(),
        vsat : new StepVsat(),
        pmr : new StepPmr(),
        gsm : new StepGsm(),
        blr : new StepBlr(),
        radar : new StepRadar()
      };
    }

     /**
     * Traitement apres chaque submit sur le wizzard
     * @param {Object}
     * @returns void
     */
    const handleStep = handleSubmit((values) => {
      if (currentStepIndex.value == 0) 
      {
        validateStepOnServer(values);
      }
      else
      {
          
          // on s'assure que la clés des documents est toujours présente
          // dans le formulaire
          var documents = !('documents' in values) ? {...{documents:[]}, ...{}} : {}
          formData.value = {...formData.value,...documents,...values};

          if(wizardAtTheEnd.value == false)
          goNext()
          else
          formSubmit()

      }
    });

    const goNext = () => {
      currentStepIndex.value++;
      if (!_stepperObj.value) 
      {
        return;
      }
      _stepperObj.value.goNext();
    }

    const onClickOnHeader = (index) => {
    
    }

    const previousStep = () => {
      if (!_stepperObj.value) {
        return;
      }

      currentStepIndex.value--;

      _stepperObj.value.goPrev();
    };
    
    const formSubmit = () => {
      
        // On construit l'URL de soumission du formulaire
        // puis on organise les données à soumettre
        // En fonction du type de réseau
        let { path, data }: { path: string; data: any; } = buildPathAndData(formData.value.typeReseau);


        //On active le loader sur le bouton
        activateLoadingButton(true);
  
        ApiService.post(path, data)
          .then( ({ data }) => 
            {
                var title = "Déclaration enregistrée"
                var message = "<stong>Félicitation</strong>! Vous venez d'enregistrer votre déclaration pour le réseau <strong>"+formData.value.typeReseau+"</strong>!"
                
                // Affichage du message de succes
                AlertMessage.onSuccess(message, title).then(() => {
                      // En cas de validation
                      // On fait une redirection
                      router.push({name:"sign-in"})
                })
            })
          .catch((error) => {
              AlertMessage.onError("Désolé, une erreur est intervenue, veuillez essayer de nouveau.")
              return false;
          })
          .finally(() => {
            activateLoadingButton(false);
          })
    };

     /**
     * Actions sur le bouton de chargement
     * @param {boolean}
     * @returns void
     */
    const activateLoadingButton = (active : boolean = false) => {
      if(submitButtonRef.value) {
        //Disable button
        submitButtonRef.value.disabled = active;
        // Activate indicator
        submitButtonRef?.value.setAttribute("data-kt-indicator", active ? "on" : "off");
      }
    }

     /**
     * Actions sur le bouton de chargement
     * @param {boolean}
     * @returns void
     */
      const activateNextLoadingButton = (active : boolean = false) => {
        if(nextButtonRef.value) {
          //Disable button
          nextButtonRef.value.disabled = active;
          // Activate indicator
          nextButtonRef?.value.setAttribute("data-kt-indicator", active ? "on" : "off");
        }
      }

     /**
     * Construction du point de terminaison et restructuration des
     * données à soumettre
     * @returns {string, any}
     */
    const buildPathAndData = (typeReseau : string) => {

      let path="";
      let data: any=Object.assign({}, formData.value);

      if(typeReseau == "FH")
      {
        var autreBandeIsSet = bandeIsAutre(formData.value.fh?.bande.id)
        var { confirmed,vsat, pmr,gsm,radar,blr,typeReseau, ...copy  } = formData.value;
        path = Path.GUEST_ADD_FH;
        data = copy;
        if(autreBandeIsSet)
        {
           data.fh.bande = null
        }
      }
      else if(typeReseau == "VSAT")
      {
        const {confirmed, blr, radar, fh, pmr, gsm, typeReseau, ...copy } = formData.value;
        path = Path.GUEST_ADD_VSAT;
        data = copy;
        
        data.vsat.lignes.forEach(el => {
          //Si la bande est autre
          if(bandeIsAutre(el.bande.id))
          //on la place à NULL
          el.bande = null
        });
      }

      else if(typeReseau == "PMR") {
        const { confirmed, blr,radar, fh, vsat,gsm,typeReseau, ...copy } = formData.value;
        path = Path.GUEST_ADD_PMR;
        data = copy;
      }
      else if(typeReseau == "GSM") {
        // const {confirmed, blr,radar, fh, vsat,pmr,  typeReseau, ...copy } = formData.value;
        const {confirmed, blr,radar, fh, vsat,pmr,  typeReseau, ...copy } = JSON.parse(JSON.stringify(formData.value));
        path = Path.GUEST_ADD_GSM;
        data = copy;
        

        // data.gsm.bande.ligneSousBandes.forEach(el => {
        //    //Si la bande est autre
        //   if(sousBandeIsAutre(el.sousBandeElementA.sousBande.id) && sousBandeIsAutre(el.sousBandeElementB.sousBande.id) )
        //   {
        //     //on la place à NULL
        //     el.sousBandeElementA.sousBande = null
        //     el.sousBandeElementB.sousBande = null
        //   }
        //   else if(sousBandeIsAutre(el.sousBandeElementA.sousBande.id) && !sousBandeIsAutre(el.sousBandeElementB.sousBande.id) )
        //   {
        //     //on la place à NULL
        //     el.sousBandeElementA.sousBande = null
        //   }
        //   else if(!sousBandeIsAutre(el.sousBandeElementA.sousBande.id) && sousBandeIsAutre(el.sousBandeElementB.sousBande.id) )
        //   {
        //     //on la place à NULL
        //     el.sousBandeElementB.sousBande = null
        //   }

        // });


        // if(bandeIsAutre(data.gsm.bande.id))
        // {
        //   data.gsm.bande = null;
        // }

        // on reforme la structures des données des documents
        delete data.confirmed;

        data.gsm.ligneSousBandes.forEach(el => {
          //Si la bande est autre
          if(sousBandeIsAutre(el.sousBandeElementA.sousBande.id) && sousBandeIsAutre(el.sousBandeElementB.sousBande.id) )
          {
            //on la place à NULL
            el.sousBandeElementA.sousBande = null
            el.sousBandeElementB.sousBande = null
          }
          else if(sousBandeIsAutre(el.sousBandeElementA.sousBande.id) && !sousBandeIsAutre(el.sousBandeElementB.sousBande.id) )
          {
            //on la place à NULL
            el.sousBandeElementA.sousBande = null
          }
          else if(!sousBandeIsAutre(el.sousBandeElementA.sousBande.id) && sousBandeIsAutre(el.sousBandeElementB.sousBande.id) )
          {
            //on la place à NULL
            el.sousBandeElementB.sousBande = null
          }

        });


        if(bandeIsAutre(data.gsm.bande.id))
        {
          data.gsm.bande = null;
          data.gsm.ligneSousBandesAutre = [...data.gsm.ligneSousBandes];
        }      
        // return data;

      }
      else if(typeReseau == "RADAR") {
        const {confirmed, blr, fh, vsat,pmr,gsm,  typeReseau, ...copy } = formData.value;
        path = Path.GUEST_ADD_RADAR;
        data = copy;
        data = {...{},...copy};
        
        data.radar.lignes.forEach(el => {
          //Si la bande est autre
          if(bandeIsAutre(el.bande.id))
          //on la place à NULL
          el.bande = null
        });
      }
      else if(typeReseau == "BLR") {
        const {confirmed, fh, vsat,pmr,gsm,radar,  typeReseau, ...copy } = formData.value;
        path = Path.GUEST_ADD_BLR;
        data = {...{},...copy};
        
        data.blr.lignes.forEach(el => {
          //Si la bande est autre
          if(bandeIsAutre(el.bande.id))
          //on la place à NULL
          el.bande = null
        });
      }
      else if(typeReseau == "RADAR") {
        const {confirmed, blr,fh, vsat,pmr,gsm, typeReseau, ...copy }=formData.value;
        path = Path.GUEST_ADD_RADAR
      }
      // on reforme la structures des données des documents
      data.documents = data.documents.map(obj => {
        var rObj = {};
        rObj["encodeFile"] = obj.uri;
        
        //Splits into an array
        var result = obj.name.split('.');  
        //Removes last value and grap the last value       
        result.pop();               
        var finalName = result.join('.');  

        rObj["fileName"] = finalName;
        return rObj;
      });

      return { path, data };
    }

    /**
     * Valide les donnéées du formulaire de chaque étape
     * sur le serveur
     * @returns {Object}
     */
      function validateStepOnServer(values: IStep1 | IStep2 | IStep3 | IStep4 | IStep5 | IStep6) 
      {
        
        var data = dataToBeValidated(values);
        activateNextLoadingButton(true);
        ApiService.post("/guest/step/" + (currentStepIndex.value + 1), data).then(response => {
          var documents = !('documents' in values) ? { ...{ documents: [] }, ...{} } : {};
          formData.value = { ...formData.value, ...documents, ...values };
          if (wizardAtTheEnd.value == false)
          {

            activateNextLoadingButton(true);
            if(formData?.value?.enregistrement_sans_declaration ==  TypeEnregistrement.SANS_DECLARATION) {
               // fais un enregistrement de compte sans déclaration
               ApiService.post("/guest/permissionnaires",formData?.value?.permissionnaire as any).then((r) => {
                  
                var title = "Compte créé"
                var message = `<strong>Félicitations</strong>! Votre compte permissionnaire a été créé!<br/>`
                              +`Un e-mail de validation de votre compte vous sera envoyé.`;

                activateNextLoadingButton(false);
                formKey.value++;
                
                // Affichage du message de succes
                AlertMessage.onSuccess(message, title).then(() => {

                  initForm();
                  resetForm({
                    values: {
                      ...formData.value,
                    },
                  });
                  goTop();
                      // En cas de validation
                      // On fait une redirection
                      // router.push({name:"sign-in"})
                })

               }).catch((error) => {
                 var supplement = "<br>" + "";
                 AlertMessage.onError("Désolé, une erreur est intervenue, veuillez essayer de nouveau." + supplement);
                 activateNextLoadingButton(false);
               })
            }
            else
            {
              goNext();
            }
          }

        }).catch(error => {

          if(error.response){
            if (error.response.data.statusCode == 400) {
              let result = updateErrorNames(error.response.data.errors);
              var message = "Désolé, " + (result.errorCount > 1 ? "des erreurs sont apparues" : "une erreur est apparue") + " sur votre formulaire, veuillez y apporter svp des corrections.";
              setErrors(result.errors);
              AlertMessage.onError(message);
            }
          }

          else {
            var supplement = "<br>" + error.response? error.response.data.message : "Une erreur inconnue s'est produite!";
            AlertMessage.onError("Désolé, une erreur est intervenue, veuillez essayer de nouveau." + supplement);
          }

        }).finally(() => {
          activateNextLoadingButton(false);
        });
    }

    function updateErrorNames(data: any) {
      let errors = {};
      let prefix = ""
      let errorCount = 0

      if (currentStepIndex.value == 0) {
        prefix ='permissionnaire.';
      }
      else if (currentStepIndex.value == 1) {
        prefix ='responsableTechniques.'
      }

      for (const [key, value] of Object.entries(data)) {
        errors[prefix+""+key] = value;
        errorCount++;
      }
      return {errors,errorCount};
    }


    function dataToBeValidated(values: IStep1 | IStep2 | IStep3 | IStep4 | IStep5 | IStep6) {
      if (currentStepIndex.value == 0) {

        return values['permissionnaire'];

      }
      else if (currentStepIndex.value == 1) {
        return values['responsableTechniques']
      }
    }

    const typeInscription = ref(TypeEnregistrement.AVEC_DECLARATION);
    const onSelectedTypeInscription = (e) => {
      typeInscription.value = e
    }

    const defaultScrollTopOptions: IScrollTopOptions = {
      offset: 200,
      speed: 600,
    }

    const goTop = () => {
      ElementAnimateUtil.scrollTop(0, defaultScrollTopOptions.speed)
    }
    

    return {
      horizontalWizardRef,
      formData,
      previousStep,
      handleStep,
      formSubmit,
      totalSteps,
      currentStepIndex,
      currentSchema,
      createNetworkSchema,
      submitButtonRef,
      nextButtonRef,
      wizardAtTheEnd,
      _stepperObj,
      errors,
      meta,
      onClickOnHeader,
      onSelectedTypeInscription,
      typeInscription,
      formKey
    };
  }
});