
import { defineComponent, computed } from "vue";
import FhForm from "@/components/networks/fh/form/FhForm.vue";
import VsatForm from "@/components/networks/vsat/form/VsatForm.vue";
import PmrForm from "@/components/networks/pmr/form/PmrForm.vue";
import GsmForm from "@/components/networks/gsm/form/GsmForm.vue";
import RadarForm from "@/components/networks/radar/form/RadarForm.vue";
import BlrForm from "@/components/networks/blr/form/BlrForm.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "step-4",
  props: {
     typeReseau : {type:String, default : true}
  },
  setup(props){

    const store = useStore()

    // Affichage du nom du tableau
    const showStepLabel = computed(() => 
    {
      let title : string
      let subtitle = "Veuillez préciser les détails du réseau"
      let icon : string
      switch (props.typeReseau) {
        case "FH":
          title = "Réseau FH"
          icon = "las la-broadcast-tower fs-1";
        break;
        case "VSAT":
          title = "Réseau VSAT"
          icon = "las la-wifi fs-1"
        break;
        case "BLR":
          title = "Réseau BLR"
          icon = "las la-wifi fs-1"
        break;
        case "RADAR":
          title = "Réseau RADAR"
          icon = "las la-wifi fs-1"
        break;
        case "PMR":
          title = "Réseau HF, UHF, VHF"
          icon = "las la-wifi fs-1"
        break;
        case "GSM":
          title = "Réseau GSM"
          icon = "las la-mobile fs-1"
        break;
        default:
          title = ""
          subtitle = ""
          icon = ""
        break;
      }
      return {title, subtitle, icon}
    })
    
    const isFh= computed(() => {return props.typeReseau == 'FH' ?  true : false})
    const isVsat= computed(() => {return props.typeReseau == 'VSAT' ?  true : false})
    const isPmr= computed(() => {return props.typeReseau == 'PMR' ?  true : false})
    const isBwwr= computed(() => {return props.typeReseau == 'BWWR' ?  true : false})
    const isGsm= computed(() => {return props.typeReseau == 'GSM' ?  true : false})
    const isBlr= computed(() => {return props.typeReseau == 'BLR' ?  true : false})
    const isRadar= computed(() => {return props.typeReseau == 'RADAR' ?  true : false})
    

    return{
      showStepLabel,
      isFh,
      isVsat,
      isPmr,
      isGsm,
      isBlr,
      isRadar,
      isBwwr
    }
  },
  components: {
    FhForm,
    VsatForm,
    PmrForm,
    GsmForm,
    BlrForm,
    RadarForm
  },
});
