
import type { ElTable } from 'element-plus'
import NewRadarModal from "@/components/modals/forms/newRadarModal/NewRadarModal.vue"

import { defineComponent, computed,onMounted,ref} from "vue";
import { Field, ErrorMessage,useFieldArray, useField} from "vee-validate";
import useEmitter from "@/composables/useEmitter";
import {useEntGeos} from "@/composables/useEntGeos";
import {useBande} from "@/composables/useBande";
import { AlertMessage } from '@/bundle/AlertMessage';
import _ from 'lodash';

    export interface Bande {
        id: string;
    }

    export interface EntGeo {
        id: string;
    }

    export interface Site {
        code: string;
        nom: string;
        longitude: string;
        latitude: string;
        entGeo: EntGeo;
    }

    export interface Entry {
        id : string;
        bande: Bande;
        largeurBande: string;
        bandeAutre: string;
        frequencePorteuse: string;
        sites: Site[];
    }

    export interface EntryWithoutKey {
        bande: Bande;
        largeurBande: string;
        bandeAutre: string;
        frequencePorteuse: string;
        sites: Site[];
    }
export default defineComponent({
  name: "radar-liste",
  props: {
     data : {type:Array, default : []},
     showAction : {type:Boolean, default : true},
  },
  components: {
    Field,
    ErrorMessage,
    NewRadarModal,
  },
  setup(props, context) {

    const emitter = useEmitter();
    const {getVilleDescription, getEntgeoDescription} = useEntGeos();
    const {getBandeDescription} = useBande("radar");
    const { remove, push,update, fields } = useFieldArray('radar.lignes');
    const { resetField:resetRadarLignes} = useField('radar.lignes',value => !!value);
    const multipleTableRef = ref<InstanceType<typeof ElTable>>()
    const multipleSelection = ref<Entry[]>([])
    const radarModal = ref<InstanceType<typeof NewRadarModal>>();


    // on écoute l'évenement d'enregistrement de l'ajout d'un lien RADAR
    emitter.on('newRadarEntryAdded', (entry) => {
      //On ajoute un  lien dans la liste
      push(entry)
    })

    // on écoute l'évenement de la modification d'un lien RADAR
    emitter.on('radarEntryUpdated', (entry) => {
      
      var currentField = fields.value.find((item,index) => {
        //Si un objet à la même clé que notre entrée
        var it : any = item?.value
        return it.hasOwnProperty('id') && it.id == entry.id
      })

      if(currentField) update(currentField.key as number,entry)
    })

    onMounted(() =>{
      resetRadarLignes()
    })

    const hasData = computed(() => {
      return fields.value.length > 0
    })

    const data = computed(() => {
      let data :any = []
      fields.value.forEach(item => {
        data.push(item.value)
      })
      return data
    })
    
    const hasItemSelected = computed(() => {
      return multipleSelection.value.length > 0;
    })

    const clearSelection = (rows?: Entry[]) => {
        multipleTableRef.value!.clearSelection()
    }

    const deleteSelection = (rows?: Entry[]) => {
        var message = "Souhaitez vous supprimer les lignes de RADAR que vous avez introduites.<br/> <strong></strong>"
        message += "<strong>N.B :</strong> Les informations supprimées seront définitivement perdues."
        var title =  "Confirmation de suppréssion"
        var options = {showCancelButton:true}

        AlertMessage.onWarning(message,title,options).then((d) => {
          /* Read more about isConfirmed, isDenied below */
          if(d.isConfirmed){
            // Effacez la selection
            // on recupere tous les ids de la selection
            var indexes : Array<string> = _.map(multipleSelection.value, 'id');
  
            //On parcourt la liste des ids
            indexes.forEach((id,index) => 
            {
              var index : number = 0
              // on parcourt la liste des lignes
              for (let v of fields.value) 
              {
                var it : any = v?.value
                //Si les ids correspondent
                if(it.id == id)
                {
                  //on retire la ligne concernée
                  remove(index);
                  multipleSelection.value = multipleSelection.value.filter(s  => { return s.id != id})
                  // on brise cette boucle
                  break;
                }
                // on incrémente l'index
                index++;
              }
            })
          }
        })
    }
    const openModal = (entry:Entry|undefined = undefined) => {
      radarModal.value!.$vfm.show('radarModal',entry)

   }
    const handleSelectionChange = (val: Entry[]) => {
      multipleSelection.value = val
    }

    return {
      hasData,
      data,
      openModal,
      multipleTableRef,
      fields,
      remove,
      clearSelection,
      getVilleDescription,
      handleSelectionChange,
      getBandeDescription,
      deleteSelection,
      multipleSelection,
      hasItemSelected,
      radarModal,
      update,
      getEntgeoDescription
    };
  }
});
