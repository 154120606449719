<template>
    <!--begin::Statistics Section-->

    <div class=" pb-15 pt-18 mt-sm-n10 ">
    <div class="landing-curve landing-dark-color">
					<svg viewBox="15 -1 1470 48" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M1 48C4.93573 47.6644 8.85984 47.3311 12.7725 47H1489.16C1493.1 47.3311 1497.04 47.6644 1501 48V47H1489.16C914.668 -1.34764 587.282 -1.61174 12.7725 47H1V48Z" fill="currentColor"></path>
					</svg>
				</div>
        <!--begin::Wrapper-->
        <div class="landing-dark-bg pb-10">
            <!--begin::Container-->
            <div class="container">
                <!--begin::Heading-->
                <div class="text-center pb-20" id="premiere-declaration" data-kt-scroll-offset="{default: 100, lg: 150}">
                    <!--begin::Title-->
                    <h3 class="fs-2hx text-white fw-bolder mb-5">Première déclaration</h3>
                    <!--end::Title-->
                    <!--begin::Description-->
                    <div class="w-100 m-0">
                        <div class="fs-5 text-white fw-bold">
                            Faites votre première déclaration et créez par le même billet votre compte qui vous <br>permettra de déclarer vos états des réseaux auprès de l'ANINF.
                        </div>
                    </div>
                    <!--end::Description-->
                </div>
                <slot></slot>
            </div>
            <!--end::Container-->
        </div>
        <!--end::Wrapper-->
         <div class="landing-curve landing-dark-color">
					<svg viewBox="15 12 1470 48" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M0 11C3.93573 11.3356 7.85984 11.6689 11.7725 12H1488.16C1492.1 11.6689 1496.04 11.3356 1500 11V12H1488.16C913.668 60.3476 586.282 60.6117 11.7725 12H0V11Z" fill="currentColor"></path>
					</svg>
				</div>
    </div>
    <!--end::Statistics Section-->
</template>

<script lang="ts" setup>

</script>

<style>

</style>