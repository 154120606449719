import { Code } from "../enum/enumeration";
import { Site } from "@/core/models/Site";
import * as Yup from "yup";
import { RegexList } from "@/bundle/RegexList";
import { TypeDocument } from "../TypeDocument";
interface Bande
{
  debut: number|undefined,
  fin: number|undefined,
  id: string,
  libelle: string
}
interface LigneFh{
  id?:string|undefined
  siteA:Site;
  siteB:Site;
  ecartDuplex: number|undefined;
  largeurBande: number|undefined;
  longueurBond: number|undefined;
  frqA:number|undefined;
  frqB:number|undefined;
}

export class StepFH { 
    //field 
     bandeAutreLibelle:string|undefined|null;
     bandeAutreFin:number|undefined|null;
     bandeAutreDebut:number|undefined|null;
     numeroAutorisation: string|undefined;

     observation: string|undefined;
     bande: Bande;
     lignes: LigneFh[];

    //constructor 
    constructor() { 
        this.bandeAutreLibelle = undefined;
        this.bandeAutreFin = undefined;
        this.bandeAutreDebut = undefined;
        this.numeroAutorisation = undefined;
        this.observation = ""
        this.lignes = [],
        this.bande = {
          debut: undefined,
          fin: undefined ,
          libelle: "" ,
          id: "" ,
        }
    }  

    static validationSchema(){
      return Yup.object({
        numeroLicence: Yup.string().label("Le n° de licence"),
        fh: Yup.object().shape
        ({

          observation: Yup.string().notRequired().label("L'observation'"),
          numeroAutorisation: Yup.string().required().label("Le n° d'autorisation"),
          bandeAutreLibelle: Yup.string().notRequired().label("La BA"),
          bandeAutreFin: Yup.number().when('bande.id', {
            is: (id) => { return (id == 'xx103b9ef9-bd99-4fdd-8a3d-625325ab75fbxs');},
            then: (schema) => schema.required().positive()
            .when('bandeAutreDebut',{
              is : (value) =>  { return (value != undefined && value !='');},
              then:(schema) => schema.test(
                'bande-autre-fin-pgpp',
                '${path} doit être superieure à celle du début',
                (value, context) => value != undefined && value > context.parent.bandeAutreDebut)
            }),
            otherwise: (schema) => schema.notRequired().nullable(),
          }).label("La fréquence de fin"),
          bandeAutreDebut: Yup.number().when('bande.id', {
            is: (id) => { return (id == 'xx103b9ef9-bd99-4fdd-8a3d-625325ab75fbxs');},
            then: (schema) => schema.required().positive()
            .when('bandeAutreFin',{
              is : (value) =>  { return (value != undefined && value !='');},
              then:(schema) => schema.test(
                  'bande-autre-dbt-pgpp',
                  '${path} doit être inferieur à celle de fin',
                  (value, context) => value != undefined && value < context.parent.bandeAutreFin,
                )
            }),
            otherwise: (schema) => schema.notRequired().nullable(),
          }).label("La fréquence de début de bande"),

          bande : Yup.object().shape({
            id: Yup.string().required().label("Le type de la bande"),
            debut: Yup.number().required().label("La fréquence"),
            fin: Yup.number().required().label("La fréquence"),
          }).default({
            id: "",
            debut: undefined,
            fin: undefined,
          }).label('La bande').typeError('La bande est obligatoire'),
          
          lignes : Yup.array().of(
            Yup.object().shape({
                      ecartDuplex: Yup.number().required().label("L'écart duplex"),
                      longueurBond: Yup.number().positive().required().label("La longueur de bond"),
                      largeurBande: Yup.number().required().label("La largeur de la bande"),
                      frqA: Yup.number().positive().required()
                      .when('bande.debut',{
                        is: (value) => {
                          return  value != undefined},
                        then: (schema) => schema.moreThan(Yup.ref("bande.debut"),"La fréquence A doit être superieure à ${more} MHz")
                      }).when('bande.fin',{
                        is: (value) => value != undefined,
                        then: (schema) => schema.lessThan(Yup.ref("bande.fin"),"La fréquence A doit être inferieure à ${less} MHz")
                      })
                     .label("La fréquence A(Tx)"),
            frqB: Yup.number().positive().required().label("La fréquence B(Rx)"),
            siteA: Yup.object().shape({ 
              code: Yup.string().required().oneOf(['A','B']).default('A').label("Le code du site"),
              nom: Yup.string().required().label("Le nom du site"),
              latitude: Yup.string().latitude().required().label('La latitude'),
              longitude: Yup.string().longitude().required().label('La longitude'),
              entGeo: Yup.object().shape({
                id: Yup.string().required().label("La localité"),
              }),
            }),
            siteB: Yup.object().shape({
              code: Yup.string().required().oneOf(['A','B']).default('B').label("Le code du site"),
              nom: Yup.string().required().label("Le nom du site"),
              latitude: Yup.string().latitude().required().label('La latitude'),
              longitude: Yup.string().longitude().required().label('La longitude'),
              entGeo: Yup.object().shape({
                id: Yup.string().required().label("La localité"),
              }),
            })
            })
          ).min(1,'Les lignes du réseau FH doivent avoir au moins 1 élèment' )
           .label('Les lignes du réseau FH'),
      })
  })
    }
 }