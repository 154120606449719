import { getBaseURL } from "@/core/helpers/config";


enum Path {
    // action types
    GUEST_ADD_VSAT = "/guest/declaration/addVsat",
    GUEST_ADD_FH   = "/guest/declaration/addFh",
    GUEST_ADD_PMR  = "/guest/declaration/addPmr",
    GUEST_ADD_BWWR = "/guest/declaration/addBwwr",
    GUEST_ADD_GSM = "/guest/declaration/addGsm",
    GUEST_ADD_BLR = "/guest/declaration/addBlr",
    GUEST_ADD_RADAR = "/guest/declaration/addRadar",

  }

  enum WizardServerValidationPath {
    // action types
    GUEST_PERMISSIONNAIRE = "/guest/permissionnaire/valid",
    GUEST_RESPONSABLE_TECHNIQUE = "/guest/declaration/addFh",

  }
export {Path,WizardServerValidationPath};
  